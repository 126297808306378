import one from "./../pic/one.jpeg"
import two from "./../pic/two.jpeg"
import three from "./../pic/three.jpeg"
import all from "./../pic/all.jpeg"
const arrswp = [
    {
        key:1,
        src:one
    },
    {
        key:2,
        src:two
    },
    {
        key:3,
        src:three
    },
    {
        key:4,
        src:all
    }
]

export default arrswp
