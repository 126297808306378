import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import arrswp from "../db/swp";

export default function Swipper() {
    return (
        <div className='swp_container'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                allowTouchMove={false} // Disable manual swiping
                modules={[Autoplay]}
                className="mySwiper"
            >
                {arrswp.map((val) => (
                    <SwiperSlide key={val.key}>
                        <div><img src={val.src} alt="swppic" /></div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
