import React, { useState, useEffect } from 'react';
import Navmin from './Nav_comp/Navmin';
import Navmax from './Nav_comp/Navmax';

function Nav() {
    const [media, setMedia] = useState(window.matchMedia("(max-width:800px)").matches);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleMediaQuery = () => {
            setMedia(window.matchMedia("(max-width:800px)").matches);
        };

        window.addEventListener("resize", handleMediaQuery);
        handleMediaQuery();

        return () => {
            window.removeEventListener("resize", handleMediaQuery);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY && window.scrollY > 100) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);



    return (
        <div>
            <div className={`navbar ${isVisible ? 'visible' : 'hidden'}`}>
                {media ? (
                    <Navmax  />
                ) : (
                    <Navmin  />
                )}
            </div>
        </div>
    );
}

export default Nav;
