import React from 'react';
import logo from "./../pic/lg3.png";

const Footer = () => {
    return (
        <div className='m-0 relative footer_cont'>
            <svg className='m-0 absolute b-1' width="100%" height="100%" id="svg" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><path d="M 0,400 L 0,150 C 202.66666666666663,158.93333333333334 405.33333333333326,167.86666666666665 563,159 C 720.6666666666667,150.13333333333335 833.3333333333335,123.46666666666667 972,119 C 1110.6666666666665,114.53333333333333 1275.3333333333333,132.26666666666665 1440,150 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="#333333" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
            <footer className="footer">
                <div className="footer__content">
                    <div className="footer__section footer__logo">
                        <img src={logo} alt="Company Logo" />
                    </div>
                    <div className='info_footer_cont'>
                        <div className="footer__section footer__location">
                            <h4>Notre emplacement</h4>
                            <address>
                                357 Bd Med V, 3éme étage N°5,Espace A6- Casablanca<br />
                                Casablanca, Morocco<br />
                            </address>
                            {/* Uncomment to add the map */}
                            {/* <iframe
                            src="https://www.google.com/maps/embed/v1/place?key=YOUR_API_KEY&q=Casablanca+Morocco"
                            width="300"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Company Location"
                        ></iframe> */}
                        </div>
                        <div className="footer__section footer__contact">
                            <h4>Contactez-nous</h4>
                            <p>Phone-1: +212 0669466157</p>
                            <p>Phone-2: +212 0669461672</p>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <p>&copy; 2024 ZL Cargo Maroc. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
