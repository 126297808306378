import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Nav from './component/Nav';
import Whoarewe from './page/Whoarewe';
import Abtus from './page/Abtus';
import OurServices from './page/Ourservice';
import WhyUs from './page/Whyus';
import Contactus from './page/Contactus';
import Footer from './page/Footer';
import Formulair from './page/Formulair';  // Import the new Formulair component

function Main() {
  const location = useLocation();

  return (
    <>
      {/* Render Nav only if the current route is not "/formulair" */}
      { location.pathname !== '/devis' && <Nav />}
      {/* { location.pathname !== '/Formulair' && <Nav />} */}
      
      <Routes>
        {/* Main page route */}
        <Route
          path="/"
          element={
            <div>
              <Whoarewe />
              <Abtus />
              <OurServices />
              <WhyUs />
              <Contactus />
              <Footer />
            </div>
          }
        />
        {/* Route for Formulair page */}
        <Route path="/devis" element={<Formulair />} />
      </Routes>
    </>
  );
}

export default Main;
