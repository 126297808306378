import React from 'react';
import Cargo from "./../pic/cargoscln.jpg";
import { useInView } from 'react-intersection-observer';
// import Logo from "./../pic/lg3.png"
function Whoarewe() {
    const { ref, inView } = useInView({
        threshold: 0.5,  // Trigger when 50% of the element is in view
        triggerOnce: true // Ensure the animation only runs once
    });
    

    return (
        <div id='Whoarewe' className='whoarewe'>
            <img className='cargo_intro' src={Cargo} alt="Cargo" />
            {/* <img  className='logo' src={Logo} alt="logo"/> */}
            <div
                ref={ref}
                className={`title ${inView ? 'visible' : ''}`}
            >
                <h1>Votre partenaire de confiance en solutions de fret mondial</h1>
                <div className='lineer'></div>
                <p>Nous sommes spécialisés dans la logistique et le transport de marchandises, garantissant efficacité et fiabilité du début à la fin. Notre expertise dans le domaine nous permet de fournir des solutions sur mesure pour tous vos besoins de transport.</p>
            </div>
        </div>
    );
}

export default Whoarewe;
