import React, { useState } from 'react';
import Logo from "./../pic/logo-removebg-preview.png";
import Home from "./../pic/back.svg";
import Tel from "./../pic/tel.svg";
import { useNavigate } from 'react-router-dom';
import Waths from "./../pic/whatsapp.png"

const Formulair = () => {
    const [colis, setColis] = useState(true);
    const [fret, setFret] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Colis state fields
    const [natureofpackage, setNatureOfPackage] = useState('');
    const [nombreofpackage, setNombreOfPackage] = useState('');
    const [colisWeight, setColisWeight] = useState('');
    const [colisDimensions, setColisDimensions] = useState('');
    const [incoterm, setIncoterm] = useState('');
    const [colisDeparture, setColisDeparture] = useState('');
    const [colisArrival, setColisArrival] = useState('');
    const [colisDestination, setColisDestination] = useState('');
    const [option_one, setOptionOne] = useState('Maritine');
    const [option_two, setOptionTwo] = useState('Express');

    // Fret state fields
    const [fretPickupAddress, setFretPickupAddress] = useState('');
    const [fretDeliveryAddress, setFretDeliveryAddress] = useState('');
    const [fretIncoterm, setFretIncoterm] = useState('');
    const [fretGoodsNature, setFretGoodsNature] = useState('');
    const [total_number_of_goods_or_freights, setTotalNumberOfGoodsOrFreights] = useState('');
    const [fretTemperature, setFretTemperature] = useState('');
    const [fretMonthlyQuantity, setFretMonthlyQuantity] = useState('');
    const [fretPoid, setFretPoids] = useState('');
    const [fretOptionOne, setFretOptionOne] = useState('Maritine');
    const [fretOptionTwo, setFretOptionTwo] = useState('Express');

    const apiUrl = 'https://zlcargo-back.onrender.com';
    const navigate = useNavigate();

    const handleActive = (type) => {
        setColis(type === 'colis');
        setFret(type === 'fret');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        const formData = {
            name,
            phone,
            colis: colis ? [{
                natureofpackage,
                nombreofpackage,
                colisWeight,
                colisDimensions,
                incoterm,
                colisDeparture,
                colisArrival,
                colisDestination,
                option_one,
                option_two
            }] : [],
            fret: fret ? [{
                fretPickupAddress,
                fretDeliveryAddress,
                fretIncoterm,
                fretGoodsNature,
                total_number_of_goods_or_freights,
                fretTemperature,
                fretMonthlyQuantity,
                fretPoid,
                option_one: fretOptionOne,
                option_two: fretOptionTwo
            }] : [],
        };
    
        const filteredFormData = Object.fromEntries(Object.entries(formData).filter(([_, value]) => value !== undefined && value !== ''));
    
        try {
            const response = await fetch(`${apiUrl}/form/sendformulair`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(filteredFormData),
            });
    
            const result = await response.json();
            setMessage(result.success ? 'Thank you for submitting the form!' : 'Error: ' + result.message);
    
            // Clear the form fields if the submission is successful
            if (result.success) {
                resetFormFields();
            }
        } catch (error) {
            setMessage('Error: Could not connect to server');
            resetFormFields();

        }
    
        setLoading(false);
    };
    
    // Function to reset the form fields
    const resetFormFields = () => {
        // Reset common fields
        setName('');
        setPhone('');
    
        // Reset Colis-specific fields
        setNatureOfPackage('');
        setNombreOfPackage('');
        setColisWeight('');
        setColisDimensions('');
        setIncoterm('');
        setColisDeparture('');
        setColisArrival('');
        setColisDestination('');
        setOptionOne('Maritine');  // Default value
        setOptionTwo('Express');   // Default value
    
        // Reset Fret-specific fields
        setFretPickupAddress('');
        setFretDeliveryAddress('');
        setFretIncoterm('');
        setFretGoodsNature('');
        setTotalNumberOfGoodsOrFreights('');
        setFretTemperature('');
        setFretMonthlyQuantity('');
        setFretPoids('');
        setFretOptionOne('');  // Reset to empty or default
        setFretOptionTwo('');  // Reset to empty or default
    };
    

    return (
        <div>
            <div className='home' onClick={() => window.location.href = "https://www.zlcargomaroc.com"}>
                <img src={Home} alt='home' />
                <span>Retour page d'accueil</span>
            </div>
            <div className="formulair-container">
                <img src={Logo} alt="Logo" />
                <form onSubmit={handleSubmit}>
                    <h1>Formulaire De Demande De Devis</h1>
                    <input
                        type="text"
                        placeholder="Nom ou société nom"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Numéro de téléphone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                    <div className="tabs">
                        <span
                            className={colis ? 'active' : ''}
                            onClick={() => handleActive('colis')}
                        >
                            Colis
                        </span>
                        <span
                            className={fret ? 'active' : ''}
                            onClick={() => handleActive('fret')}
                        >
                            Frets / Marchandises
                        </span>
                    </div>

                    {colis && (
                        <div className="colis-fields">
                            <input
                                type="text"
                                value={natureofpackage}
                                onChange={(e) => setNatureOfPackage(e.target.value)}
                                placeholder="Nature du colis"
                            />
                            <div className='select_option'>
                                <select value={option_one} onChange={(e) => setOptionOne(e.target.value)}>
                                    <option value="Maritine">Maritime</option>
                                    <option value="Aérienne">Aérienne</option>
                                    <option value="Routier">Routier</option>
                                </select>
                                <select value={option_two} onChange={(e) => setOptionTwo(e.target.value)}>
                                    <option value="Express">Express</option>
                                    <option value="Economie">Economie</option>
                                </select>
                            </div>
                            <input
                                type="text"
                                value={nombreofpackage}
                                onChange={(e) => setNombreOfPackage(e.target.value)}
                                placeholder="Nombre de colis"
                            />
                            <input
                                type="text"
                                value={colisWeight}
                                onChange={(e) => setColisWeight(e.target.value)}
                                placeholder="Poids"
                            />
                            <input
                                type="text"
                                value={colisDimensions}
                                onChange={(e) => setColisDimensions(e.target.value)}
                                placeholder="Dimensions"
                            />
                            <input
                                type="text"
                                value={incoterm}
                                onChange={(e) => setIncoterm(e.target.value)}
                                placeholder="Incoterm"
                            />
                            <input
                                type="text"
                                value={colisDeparture}
                                onChange={(e) => setColisDeparture(e.target.value)}
                                placeholder="Lieu de départ"
                            />
                            <input
                                type="text"
                                value={colisArrival}
                                onChange={(e) => setColisArrival(e.target.value)}
                                placeholder="Lieu d'arrivée"
                            />
                            <input
                                type="text"
                                value={colisDestination}
                                onChange={(e) => setColisDestination(e.target.value)}
                                placeholder="Destination"
                            />
                        </div>
                    )}

                    {fret && (
                        <div className="fret-fields">
                            <input
                                type="text"
                                value={fretPickupAddress}
                                onChange={(e) => setFretPickupAddress(e.target.value)}
                                placeholder="Adresse de ramassage"
                            />
                            <input
                                type="text"
                                value={fretDeliveryAddress}
                                onChange={(e) => setFretDeliveryAddress(e.target.value)}
                                placeholder="Adresse de livraison"
                            />
                            <input
                                type="text"
                                value={fretIncoterm}
                                onChange={(e) => setFretIncoterm(e.target.value)}
                                placeholder="Incoterm"
                            />
                            <input
                                type="text"
                                value={fretGoodsNature}
                                onChange={(e) => setFretGoodsNature(e.target.value)}
                                placeholder="Nature de marchandises ou de frets"
                            />
                            <div className='select_option'>
                                <select value={fretOptionOne} onChange={(e) => setFretOptionOne(e.target.value)}>
                                    <option value="Maritime">Maritime</option>
                                    <option value="Aérienne">Aérienne</option>
                                    <option value="Routier">Routier</option>
                                </select>
                                <select value={fretOptionTwo} onChange={(e) => setFretOptionTwo(e.target.value)}>
                                    <option value="Express">Express</option>
                                    <option value="Economie">Economie</option>
                                </select>
                            </div>
                            <input
                                type="text"
                                value={total_number_of_goods_or_freights}
                                onChange={(e) => setTotalNumberOfGoodsOrFreights(e.target.value)}
                                placeholder="Nombre total de marchandises ou de frets"
                            />
                            <input
                                type="text"
                                value={fretPoid}
                                onChange={(e) => setFretPoids(e.target.value)}
                                placeholder="Poids"
                            />
                            <input
                                type="text"
                                value={fretTemperature}
                                onChange={(e) => setFretTemperature(e.target.value)}
                                placeholder="Température requise"
                            />
                            <p className="remark">* Si vos marchandises ne nécessitent pas un transport réfrigéré, cette section peut être laissée vide.</p>
                            <input
                                type="text"
                                value={fretMonthlyQuantity}
                                onChange={(e) => setFretMonthlyQuantity(e.target.value)}
                                placeholder="Quantité mensuelle"
                            />
                        </div>
                    )}

                    <button type="submit" disabled={loading}>
                        {loading ? 'Envoyer...' : 'Envoyer'}
                    </button>
                </form>

                {/* {message && <div className="message">{message}</div>} */}
            </div>
            <div className='moreinfo'>
                <h4 className=''>Pour toute aide ou conseil vous pouvez nous envoyer un Message ou nous appeler :</h4>
                <div>
                    <a href='tel:0669461672'>
                        <img src={Waths} alt="waths" />
                    </a>
                </div>
                <p>cliquez sur les icônes WhatsApp</p>
            </div>
        </div>
    );
};

export default Formulair;
