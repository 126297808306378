import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

function Contactus() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [text, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const apiUrl = 'https://zlcargo-back.onrender.com';
    // const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';
    
    // Using useInView hook
    const { ref: headerRef, inView: headerInView } = useInView({ triggerOnce: true });
    const { ref: formRef, inView: formInView } = useInView({ triggerOnce: true });
    
    const Handlesub = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");  // Clear previous errors
        const Content = { name, email, number, text };
    
        try {
            const res = await fetch(`${apiUrl}/email`, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(Content)
            });
    
            const contentType = res.headers.get("Content-Type");
    
            if (!res.ok) {
                throw new Error("Network response was not ok");
            }
    
            if (contentType && contentType.includes("application/json")) {
                const data = await res.json();
                console.log(data);
            } else {
                const text = await res.text();
                console.log(text);
            }
    
            // Clear the form fields after successful submission
            setName("");
            setEmail("");
            setNumber("");
            setMessage("");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError("An error occurred. Please try again.");
            console.log(error);
        }
    };
    

    return (
        <div id='contact' className='contact_cont'>
            <div className="contactus">
                <div
                    ref={headerRef}
                    className={`contactus-header ${headerInView ? 'fade-in' : ''}`}
                >
                    <span>Contactez-Nous</span>
                </div>
                <div
                    ref={formRef}
                    className={`contactus-form ${formInView ? 'slide-in' : ''}`}
                >
                    <form onSubmit={Handlesub}>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Nom & Prenom ..."
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email@Email.com"
                        />
                        <input
                            type="tel"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            placeholder="Tel:0669466157"
                        />
                        <textarea
                            value={text}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="écrire un message..."
                        ></textarea>
                        <button type="submit">
                            {loading ? "Envoye..." : error ? "Error. Try Again" : "Envoyer un message"}
                        </button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            </div>
        </div>
    );
}

export default Contactus;
