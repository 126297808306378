import React from 'react';
import { useInView } from 'react-intersection-observer';
import Whyuspic from "./../pic/whyrmbback.png";
import { whyusarr } from '../db/whyusarr';

function Whyus() {
    // Intersection Observer hooks
    const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: true });
    const { ref: imgRef, inView: imgInView } = useInView({ triggerOnce: true });
    const { ref: explainRef, inView: explainInView } = useInView({ triggerOnce: true });

    return (
        <div id='whyus' className='whyus'>
            <div className={`ttl ${titleInView ? 'animate' : ''}`} ref={titleRef}>
                <span>Pourquoi-Nous ?</span>
            </div>
            <div className='whyus_content_cont'>
                <div className={`img_cont ${imgInView ? 'animate' : ''}`} ref={imgRef}>
                    {/* <img src={Whyuspic} alt="Whyus" /> */}
                </div>
                <div className={`why_us_explaination ${explainInView ? 'animate' : ''}`} ref={explainRef}>
                    {whyusarr.map((val, index) => (
                        <div className='whyus_item' key={val.key} style={{ '--index': index }}>
                            <span className='circle'>{val.key}</span>
                            <div className='whyus_text'>
                                <span className='title'>{val.title}</span>
                                <p>{val.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Whyus;
