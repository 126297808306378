import React from 'react';
import { useInView } from 'react-intersection-observer';
import arfr from "./../pic/airfr.jpeg";
import ocfr from "./../pic/ocnfr.jpeg";
import rdfr from "./../pic/rdfr.jpeg";

const OurServices = () => {
  const services = [
    {
      title: 'Fret aérien',
      description: 'Des solutions de transport aérien rapides et fiables.',
      imgSrc: arfr,
    },
    {
      title: 'Fret maritime',
      description: 'Services de fret maritime rentables et étendus.',
      imgSrc: ocfr,
    },
    {
      title: 'Fret routier',
      description: 'Transport terrestre efficace et rapide.',
      imgSrc: rdfr,
    },
  ];

  const [titleRef, titleInView] = useInView({ threshold: 0.2, triggerOnce: true });

  const [ref1, inView1] = useInView({ threshold: 0.2, triggerOnce: true });
  const [ref2, inView2] = useInView({ threshold: 0.2, triggerOnce: true });
  const [ref3, inView3] = useInView({ threshold: 0.2, triggerOnce: true });

  const refs = [ref1, ref2, ref3];
  const inViews = [inView1, inView2, inView3];

  return (
    <section id='ourservices' className="our-services">
      <h2 ref={titleRef} className={`our-services__title ${titleInView ? 'our-services__title--visible' : ''}`}>
        Nos prestations
      </h2>
      <div className="services-container">
        {services.map((service, index) => (
          <div
            ref={refs[index]}
            className={`service-item ${inViews[index] ? 'service-item--visible' : ''}`}
            key={index}
          >
            <div className="service-image">
              <img src={service.imgSrc} alt={service.title} />
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurServices;
