import React, { useState, useEffect } from 'react';
import Logo from "./../../pic/lg3.png"
import Tel from "./../../pic/tel.svg"
import Map from "./../../pic/map.svg"
import { Link } from 'react-router-dom';

function Navmax() {
    const [openmenu, Setmenu] = useState(false);

    const Openmenu = () => {
        Setmenu(!openmenu);
    };

    const handleLinkClick = () => {
        Setmenu(false); // Close the menu
        document.body.style.overflow = 'auto'; // Re-enable scroll
    };

    useEffect(() => {
        if (openmenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [openmenu]);

    return (
        <div className="relative">
            <div className='info_contact'>
                <div><a href='tel:0669461672'><img src={Tel} alt="tel" /></a>(+212) 669-461672</div>
                <div><a href='https://maps.app.goo.gl/szR3XTySdAm89LTdA'><img src={Map} alt="map" /></a></div>
            </div>
            <div className="navmax">
                <div onClick={Openmenu} className="burger">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="logo">
                    {/* <span>Zl Cargo Maroc</span> */}
                    <img src={Logo} alt="ZL Cargo Maroc" />
                </div>
            </div>
            <div className={`backdrop ${openmenu ? 'open' : ''}`} onClick={Openmenu}>
                <div className={`Menu ${openmenu ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
                    <div className="close-btn" onClick={Openmenu}>X</div>
                    <a href='#Whoarewe' onClick={handleLinkClick}>Homme</a>
                    <a href='#Aboutus' onClick={handleLinkClick}>Nous Sommes</a>
                    <a href='#ourservices' onClick={handleLinkClick}>Nos Prestations</a>
                    <a href='#whyus' onClick={handleLinkClick}>Pourquoi nous</a>
                    <Link to="devis" onClick={handleLinkClick}>Devis</Link>
                    <a href='#contact' onClick={handleLinkClick}>Contactez-nous</a>
                    <div className="company-name">Zl Cargo Maroc</div>
                </div>
            </div>
        </div>
    );
}

export default Navmax;
