import React from "react"

export const whyusarr = [
    {
        key:1,
        title:`Expertise et Connaissance du Marché
        `,
        description:`Grâce à une vaste expérience dans le domaine du transport, nous comprenons parfaitement les défis logistiques et réglementaires. Nous savons comment optimiser chaque étape du processus pour garantir un transport fluide et sans tracas.`
    },
    {
        key:2,
        title:`Réseau Étendu de Transporteurs`,
        description:`Nous avons établi des relations solides avec un réseau mondial de transporteurs fiables. Cela nous permet de négocier les meilleures conditions et de choisir les options de transport les plus adaptées aux besoins spécifiques de chaque client.`
    },
    {
        key:3,
        title:`Solutions Personnalisées`,
        description:`Chaque client a des besoins uniques, c'est pourquoi nous offrons des solutions sur mesure. Que vous ayez besoin de transport express, d'entreposage temporaire ou de gestion des formalités douanières, nous adaptons nos services pour répondre exactement à vos attentes.`
    },
    {
        key:4,
        title:`Service Client Proactif`,
        description:`Notre service client est réactif et proactif. Nous ne nous contentons pas de répondre à vos questions, nous anticipons vos besoins et vous tenons informé en temps réel de l’état de vos envois.`
    },

]