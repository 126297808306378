import React from 'react';
import Swipper from '../component/Swipper';
import { useInView } from 'react-intersection-observer';

function Abtus() {
    const { ref, inView } = useInView({
        threshold: 0.5,  // Trigger when 50% of the element is in view
        triggerOnce: true, // Ensure the animation only runs once
    });

    const { ref: refring, inView: inViewswp } = useInView({
        threshold: 0.5,  // Trigger when 50% of the element is in view
        triggerOnce: true, // Ensure the animation only runs once
    });

    return (
        <section id='Aboutus' className="about-us">
            <div ref={ref} className={`about-us__content ${inView ? 'Visibleintro' : ''}`}>
                <h2 className="about-us__title">Qui nous sommes ?</h2>
                <p className="about-us__text">
                    Nous sommes spécialistes de la logistique et du transport de marchandises, garantissant efficacité et fiabilité du début à la fin. Notre expertise dans le domaine nous permet de vous proposer des solutions sur mesure pour répondre à tous vos besoins de transport.                </p>
                <p className="about-us__text">
                Avec un réseau mondial et des années d'expérience, nous nous engageons à fournir des services de premier ordre auxquels vous pouvez faire confiance. Notre mission est de faciliter des solutions transparentes de logistique et de fret qui aident votre entreprise à prospérer.                </p>
            </div>
            <div ref={refring} className={`about-us__image ${inViewswp ? 'Visibleswp' : ''}`}>
                <Swipper />
            </div>
        </section>
    );
}

export default Abtus;
